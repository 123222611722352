.lesson-list-item

  &__link
    display: grid
    grid-template-columns: auto 1fr
    column-gap: $spacing * 0.5
    row-gap: $spacing * 0.25
    color: inherit
    border-radius: $spacing * 0.5
    padding: $spacing * 0.5

    +desktop
      padding: $spacing

    &:hover
      background-color: $color--mine-shaft--dark
      text-decoration: none

  &__icon
    width: $spacing * 1.5
    height: $spacing * 1.5

    +tablet
      height: $spacing * 2
      width: $spacing * 2

  &__content
    display: flex
    flex-direction: column
    margin: auto 0
    gap: $spacing * 0.25

  &__header, &__description, &__controls
    margin: 0

  &__separator
    font-weight: normal
    font-style: italic

    +less-than-tablet
      display: none

  &__title, &__source-name
    +less-than-tablet
      display: block

  &__source-name
    +less-than-tablet
      font-weight: normal

  &__description
    font-style: italic
    color: $color--silver-chalice

  &__controls
    font-size: 0.9rem
    display: flex
    justify-content: start
    margin-left: -$spacing * 0.5

    // HACK: Decrese the padding of the buttons.
    & > *.button
      padding-left: $spacing * 0.5
      padding-right: $spacing * 0.5
