.score-summary-chart
  position: relative

  svg
    background-color: $color--mine-shaft--dark
    border-radius: $spacing * 0.5

  &__area
    @each $state, $color in $state-colors
      &--#{ $state } .recharts-area-area
        fill: $color
        fill-opacity: 1

    // Override the unsolved color.
    &--unsolved .recharts-area-area
      fill: $color--tundora
      fill-opacity: 0.6

  .recharts-tooltip-cursor
    stroke: $color--concrete

  &__no-data
    position: absolute
    top: 0
    right: 0
    bottom: 0
    left: 0

    display: flex
    align-items: center
    justify-content: center
    font-style: italic
    margin: 0

    text-shadow: 0 0 1px $color--mine-shaft--dark
