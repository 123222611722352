.score-summary-stats
  display: grid
  grid-template-columns: repeat(2, 1fr)
  grid-template-rows: auto repeat(2, 1fr) auto repeat(2, 1fr)
  gap: $spacing * 0.5

  +desktop
    grid-template-columns: repeat(4, 1fr)
    grid-template-rows: auto repeat(2, 1fr)
    gap: $spacing

  &__time-period-header, &__current-day-header
    margin: 0

  &__time-period-header
    grid-row: 1
    grid-column: 1 / span 2

  &__current-day-header
    grid-row: 4
    grid-column: 1 / span 2

  &__chart
    grid-column: 1 / span 2
    grid-row: 2 / span 2

    // BUG FIX: For some reason, this is required on mobile.
    width: 100%

  +desktop
    &__time-period-header
      grid-row: 1
      grid-column: 1 / span 2

    &__current-day-header
      grid-row: 1
      grid-column: 3 / span 2

    &__chart
      grid-column: 1 / span 2
      grid-row: 2 / span 2

  &__stat
    display: flex
    align-items: center
    gap: $spacing * 0.5

    background-color: $color--mine-shaft--dark
    border-radius: $spacing * 0.5
    padding: $spacing * 0.75 $spacing * 0.5

  &__icon
    height: $spacing * 1.25
    grid-row: 1 / span 2

  &__stat-value
    margin: 0

  &__number
    font-weight: bold
