.blurb
  background-color: $color--mine-shaft--dark
  padding: $spacing
  width: ($tablet-width + $mobile-width) * 0.5
  max-width: 100%
  border-radius: $spacing * 0.5

  &__message
    margin: 0
    font-style: italic
    text-align: center

  &__actions
    margin-top: $spacing
    text-align: center

  &__icon
    display: block
    margin: 0 auto $spacing * 0.75
    color: $color--true-v
    height: $spacing * 1.5
