@use "sass:map"

.exercise-controls

  &__button

    &--hint, &--assist
      &:disabled
        opacity: 1

    // TODO: Generate these colors.
    &--hint
      color: map.get($state-colors, "hint")

    &--assist
      color: map.get($state-colors, "assist")
