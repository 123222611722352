.fen-input-with-label

  &__textarea
    font-family: $font-family--monospace
    overflow: hidden

    // HACK: I believe this property is deprecated, but this is the only thing I could get to work
    // in Firefox.
    word-break: break-all
    overflow-wrap: anywhere
