code
  display: inline-block
  background-color: $color--mine-shaft--darkest
  padding: 0 $spacing * 0.125
  border-radius: $spacing * 0.125

  // BUG FIX: The monospace font has a slightly larger x-height than the default font. When using
  // display: inline-block, this causes the code block to have a slightly larger line-height. To fix
  // this, I'm shortening the line height so the parent line height takes precedence.
  line-height: 1.25rem
