.lessons-index-page
  +page-padding($vertical: false)
  margin: 0 auto
  width: fit-content
  max-width: $desktop-width

  &__lessons
    margin: $spacing auto

    +less-than-desktop
      margin-left: -$spacing * 0.5
      margin-right: -$spacing * 0.5

  &__blurb
    margin: $spacing auto
