.modal
  $modal-border-radius: $spacing * 0.5
  $modal-icon-size: $spacing * 0.75

  position: absolute
  top: 0
  bottom: 0
  right: 0
  left: 0
  z-index: 1000

  display: flex
  align-items: center
  justify-content: center

  transition: background-color $transition-time--small ease-in-out

  &:not(.modal--open)
    pointer-events: none

  &--open.modal--requires-interaction
    background-color: transparentize($color--black, 0.5)

  &__container
    width: 100%
    max-width: $mobile-width
    position: relative
    margin: $spacing * 0.5

    border-radius: $modal-border-radius
    box-shadow: 0 $spacing * 0.5 $spacing * 2 transparentize($color--black, 0.5)
    background-color: $color--mine-shaft--dark

    transition: all $transition-time--small ease-in-out

    +desktop
      margin: $spacing

    .modal:not(.modal--open) &
      transform: scale(0.5)
      opacity: 0

  &__close
    position: absolute
    top: $spacing * 0.25
    right: $spacing * 0.25
    padding: ($navigation-height - $spacing * 0.75) * 0.5 - $spacing * 0.25

  &__close-icon
    width: $modal-icon-size
    height: $modal-icon-size
    color: $color--boulder

  &__title
    display: flex
    align-items: center
    height: $navigation-height
    margin: 0
    background-color: $color--mine-shaft--darkest
    text-align: center
    justify-content: center

    border-radius: $modal-border-radius $modal-border-radius 0 0
