.chessboard-promotion
  background-color: transparentize($color--white, 0.1)
  box-shadow: 0 0 $spacing * 0.5 transparentize($color--mine-shaft, 0.65)

  position: absolute

  display: flex
  flex-direction: column

  width: 12.5%
  height: 50%

  @for $index from 1 through 8
    &[data-file-index="#{ $index }"]
      left: #{ 100% * 0.125 * $index }

  &[data-position="top"]
    top: 0

  &[data-position="bottom"]
    bottom: 0
    flex-direction: column-reverse

  &__button
    flex: 1 1 0

    border-radius: 0

    &, &:hover
      background-color: transparent

    // HACK: Override the padding selector with something more specific.
    &.chessboard-promotion__button
      padding: 0

  &__piece
    height: 100%
