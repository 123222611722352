.secondary-navigation
  $link-height: 1.5rem + $spacing * 0.5

  background-color: $color--mine-shaft--dark
  font-size: 0.8rem

  +desktop
    display: flex
    gap: $spacing * 0.5
    align-items: center
    padding: 0 $spacing
    height: $navigation-height

  &__title
    margin: 0 auto 0 0
    position: relative
    z-index: 100
    background-color: $color--mine-shaft--dark

    // Hide the title when it's too long
    overflow: hidden
    text-overflow: ellipsis
    white-space: nowrap

    +less-than-desktop
      padding: $spacing * 0.5
      font-size: 1rem

  &__title-link
    &, &:focus, &:hover
      color: inherit

  &__separator
    pointer-events: none
    color: $color--boulder

    +less-than-desktop
      display: none

  &__link
    font-style: italic
    color: $color--silver-chalice

    transition: transform $transition-time--small ease-in-out

    +less-than-desktop
      width: 100%
      position: absolute
      top: $navigation-height
      display: block
      background-color: $color--mine-shaft--dark
      padding: $spacing * 0.25 $spacing * 0.5

      &:nth-of-type(2)
        top: $navigation-height + $link-height

      &:nth-of-type(3)
        top: $navigation-height + $link-height * 2

      // HACK: Using 3D transforms on links can cause small sub-pixel artifacts to appear  between
      // them. Rather than fighting against this, the simplest way to fix it is to create a small
      // box shadow that overlaps each item to get rid of the artifact.
      .secondary-navigation--open &
        box-shadow: 0 1px 0 0 $color--mine-shaft--dark

      .secondary-navigation:not(.secondary-navigation--open)[data-number-of-items="2"] &
        transform: translate3d(0, -$link-height * 2, 0)

      .secondary-navigation:not(.secondary-navigation--open)[data-number-of-items="3"] &
        transform: translate3d(0, -$link-height * 3, 0)


  &__open-button
    position: absolute
    top: 0
    right: 0
    width: $navigation-height
    z-index: 300

  &[data-number-of-items="1"] .secondary-navigation__open-button
    display: none

  &__button.secondary-navigation__button
    font-size: 0.9rem

    +less-than-desktop
      display: none
