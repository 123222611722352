.menu-open-button

  // BUG FIX: Without this property, the menu bar's background sits under the app's other content.
  z-index: 1000

  @mixin icon($icon-size)
    width: $icon-size
    height: $icon-size

    position: absolute
    top: ($navigation-height - $icon-size) * 0.5
    right: ($navigation-height - $icon-size) * 0.5
    z-index: 200

  display: flex
  position: relative

  height: $navigation-height
  width: $navigation-height
  padding: 0
  border-radius: 0

  // HACK: For some reason, the button is only clickable on the icon by default. This causes issues
  // on mobile where we need the target to be bigger. To get around this, I'm adding padding so the
  // entire button will be clickable.
  padding: $navigation-height * 0.5

  &, &:hover
    background-color: transparent

  +desktop
    display: none

  // The icon is displayed absolutely so the button can be resized and the icon will still sit in
  // the top-right corner.
  &__caret
    +icon($spacing * 0.5)
    transition: transform $transition-time--small ease-in-out

    .menu-open-button[data-direction="down"] &
      transform: rotateZ(-180deg)

  &__bars, &__info
    +icon($spacing * 0.675)
    fill: $color--silver

  &__info, &__caret
    fill: $color--silver-chalice
