$color--black: #000;
$color--mine-shaft--darkest: #1f1f1f;
$color--mine-shaft--dark: #292929;
$color--mine-shaft: #333333;
$color--tundora: #484848;
$color--scorpion: #5d5d5d;
$color--boulder: #747474;
$color--silver-chalice: #B0B0B0;
$color--silver: #cacaca;
$color--concrete: #f2f2f2;
$color--white: #fff;

$color--aqua-forest: #56af7b;
$color--malibu: #72bbff;
$color--indigo: #5f68c4;
$color--true-v: #856bd6;
$color--moon-raker: lighten(#ccc2ef, 7%);
$color--fuzzy-wuzzy-brown: #c35151;
$color--saffron: #f4ce2c;
$color--neon-carrot: #f99d3f;

$state-colors: (
  "unsolved": $color--boulder,
  "perfect": $color--aqua-forest,
  "hint": $color--saffron,
  "assist": $color--neon-carrot,
  "mistake": $color--fuzzy-wuzzy-brown,
  "content": $color--true-v
);

$marker-colors: (
  "assist": $color--neon-carrot,
  "check": $color--black,
  "drag": $color--malibu,
  "hint": $color--saffron,
  "lastMoveFrom": $color--malibu,
  "lastMoveTo": $color--malibu,
  "legalMove": transparentize($color--mine-shaft--darkest, 0.9),
  "selected": $color--malibu,
  "perfect": $color--aqua-forest,
  "user": $color--malibu,
  "mistake": $color--fuzzy-wuzzy-brown
);
