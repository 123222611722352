@use "sass:math"

$spacing: 1.5rem
$spacing--small: $spacing * 0.5
$spacing--xsmall: $spacing * 0.25

$mobile-width: 320px
$tablet-width: 640px
$desktop-width: 960px
$large-desktop-width: 1280px

$navigation-height: $spacing * 2
$side-width: 30vw
$large-side-width: $large-desktop-width * 0.3
$icon-size: math.div($spacing * 5, 6)
