.toggle-button
  background-color: transparentize($color--black, 0.75)
  border-radius: $spacing
  position: relative

  &:disabled
    opacity: 0.4

  &::before
    display: block
    content: ""
    height: 1.5rem + $spacing * 0.5
    border-radius: $spacing
    width: calc(100% / var(--number-of-options))
    background-color: $color--true-v
    position: absolute
    top: 0
    left: 0
    z-index: 100
    transition: transform $transition-time--xsmall ease-in-out
    transform: translate3d(calc(100% * var(--selected-index)), 0, 0)

  &__labels
    position: relative
    display: flex
    z-index: 200

  &__label
    flex: 1 1 0
    margin: 0
    text-align: center
    line-height: inherit
    padding: $spacing * 0.25 0

    cursor: pointer
    font-weight: 600

  &__radio
    display: none
