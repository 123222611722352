.auto-move-bar
  $background-color: $color--tundora
  $line-color: $color--silver-chalice
  $height: $spacing * 0.75

  display: flex
  align-items: center
  justify-content: center

  height: $height
  margin: $spacing * 0.5 0

  background-color: $background-color
  font-size: 0.75rem
  font-style: italic
  border-radius: $spacing

  // Add the background line
  background: linear-gradient(transparent calc(50% - 0.5px), $line-color calc(50% - 0.5px), $line-color calc(50% + 0.5px), transparent calc(50% + 0.5px)), $color--tundora
  box-shadow: inset $spacing * 0.5 0 $background-color, inset (-$spacing * 0.5) 0 $background-color

  &__content
    display: flex
    align-items: center
    background-color: $background-color
    padding: 0 $spacing * 0.5
    line-height: $height
    user-select: none

  &__icon
    height: 1.25em
    margin-right: $spacing * 0.25
