.controls
  display: flex
  justify-content: center
  align-items: center
  height: $navigation-height
  font-size: 0.9rem

  +less-than-desktop
    background-color: $color--mine-shaft--darkest

  +desktop
    gap: $spacing * 0.25
    padding-left: $spacing * 0.5
    padding-right: $spacing * 0.5

  &__arrows
    display: flex
    margin-left: auto

  &__button--error
    color: $color--fuzzy-wuzzy-brown

    &:disabled
      opacity: 1

  // HACK: Reduce the button padding on mobile to make more room for the controls.
  & > .button
    padding-left: $spacing * 0.5
    padding-right: $spacing * 0.5
