.log-in-page

  &__forgot-password
    margin: 0 0 #{ -$spacing--small } $spacing--small

  &__forgot-password-link
    font-size: 0.75rem
    font-style: italic

  &__password-input
    margin-bottom: $spacing--xsmall

  &__anchor
    text-decoration: underline
