.password-strength
  display: flex
  height: $spacing--xsmall
  margin: 0 $spacing * 0.5
  gap: $spacing * 0.5

  $colors: ($color--fuzzy-wuzzy-brown, $color--saffron, $color--aqua-forest, $color--aqua-forest)

  &__level
    display: block
    flex: 1 1 0
    background-color: $color--tundora
    border-radius: $spacing--xsmall * 0.5

  @for $n from 1 through 4
    @for $strength from 1 through 4
      @if $n <= $strength
        &[data-password-strength="#{ $strength }"] .password-strength__level:nth-of-type(#{ $n })
          background-color: nth($colors, $strength)
