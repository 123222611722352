.edit-starting-position-sidebar
  padding: 0 $spacing

  &__label
    margin: 0
    text-align: center

  &__position-buttons
    display: flex
    gap: $spacing * 0.25
    justify-content: stretch
    margin: $spacing 0

  &__position-button
    flex: 1 1 0
    padding-left: 0
    padding-right: 0

  &__castling
    margin: $spacing * 2 0

  &__player-controls
    display: grid
    grid-auto-flow: column
    grid-template-columns: 1fr 1fr
    grid-template-rows: auto auto
    column-gap: $spacing
    row-gap: $spacing * 0.25
    margin: $spacing * 1.5 0 $spacing * 2
