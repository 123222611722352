.preview-lesson

  &--link
    display: flex
    align-items: center
    justify-content: center

  // HACK: There's currently no easy way to override the value for the twitch player, so for now I'm
  // using the provided ID and this technique: https://bit.ly/3p9vXqK.
  #twitch-embed iframe
    position: absolute
    top: 0
