.practice-exercise
  +page-padding
  display: flex
  flex-direction: column
  gap: $spacing * 0.5
  padding: $spacing * 0.5 0

  // HACK: On mobile, visually center the chessboard a little more.
  +less-than-tablet
    padding-bottom: $spacing * 1.5

  +tablet
    gap: $spacing
    padding: $spacing 0

  &__description-container
    padding: 0 $spacing * 0.5

    +desktop
      padding: 0 $spacing

    // Reserve space for the description on mobile
    +less-than-desktop
      min-height: $spacing + $base-line-height * 2

  &__description
    margin: 0 auto

  &__chessboard-container
    flex: 1 1 0
