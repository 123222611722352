.cm-chessboard

  .board
    fill: transparent

  .square.black
    fill: $color--true-v

  .square.white
    fill: $color--moon-raker
