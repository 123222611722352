.chessboard-arrow
  opacity: 0.85
  pointer-events: none

  &[data-type="assist"]
    --arrow-color: #{ $color--neon-carrot }

  &[data-type="user"]
    --arrow-color: #{ $color--neon-carrot }

  &__line
    stroke: var(--arrow-color)

  &__triangle
    fill: var(--arrow-color)
