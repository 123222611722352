@mixin mobile
  @media only screen and (min-width: $mobile-width)
    @content

@mixin tablet
  @media only screen and (min-width: $tablet-width)
    @content

@mixin desktop
  @media only screen and (min-width: $desktop-width)
    @content

@mixin large-desktop
  @media only screen and (min-width: $large-desktop-width)
    @content

@mixin less-than-tablet
  @media only screen and (max-width: #{ $tablet-width - 1px })
    @content

@mixin less-than-desktop
  @media only screen and (max-width: #{ $desktop-width - 1px })
    @content

@mixin less-than--large-desktop
  @media only screen and (max-width: #{ $desktop-width - 1px })
    @content

@mixin print
  @media print
    @content
