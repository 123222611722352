@use "sass:math"

$number-of-pieces: 31
$piece-size: math.div(100vw, $number-of-pieces)
$positioning-group-size: 13
$animation-duration: 10s

$mobile-piece-multiplier: 3
$tablet-piece-multiplier: 2.25
$desktop-piece-multiplier: 1.5

.raining-chess-pieces
  position: absolute
  top: 0
  right: 0
  bottom: 0
  left: 0
  overflow: hidden

  --piece-size: calc(#{ $piece-size } * #{ $mobile-piece-multiplier })

  +tablet
    --piece-size: calc(#{ $piece-size } * #{ $tablet-piece-multiplier })

  +desktop
    --piece-size: calc(#{ $piece-size } * #{ $desktop-piece-multiplier })

  &__piece
    width: var(--piece-size)
    height: var(--piece-size)

    position: absolute

    animation: falling $animation-duration infinite linear
    top: calc(-1 * var(--piece-size))
    color: $color--tundora

    @for $index from 1 through $number-of-pieces
      &:nth-of-type(#{ $index })
        left: calc(#{ math.div($index - 0.5, $number-of-pieces) * 100% } - var(--piece-size) / 2)
        $positional-index: $index * $positioning-group-size % $number-of-pieces
        animation-delay: math.div($positional-index - 1, $number-of-pieces) * $animation-duration - $animation-duration

@keyframes falling
  100%
    transform: translateY(calc(100vh + var(--piece-size))) rotate(540deg)
