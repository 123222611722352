.edit-exercise-sidebar
  padding: 0 $spacing

  &__actions
    margin: $spacing * 1.5 0
    display: flex
    flex-direction: column
    align-items: center
    gap: $spacing

  &__subheading
    text-align: center
    margin-bottom: 0

  // TODO: Standardize this style?
  &__error
    font-style: italic
    font-size: 0.9rem
    text-align: center
    color: $color--fuzzy-wuzzy-brown
    margin: $spacing * 0.5 0 0
