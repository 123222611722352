h1, h2
  font-size: 1.1rem
  margin: $spacing 0
  font-weight: 700

h3, h4
  font-size: 1rem

label
  margin: $spacing 0
  display: block
  font-size: 0.9rem
  font-weight: bold
  line-height: $spacing

.label--disabled
  opacity: 0.3
