.show-lesson-page
  max-width: $desktop-width
  margin: 0 auto

  &__exercises
    display: grid
    grid-template-columns: 1fr
    gap: $spacing
    margin: $spacing 0

    +tablet
      grid-template-columns: 1fr 1fr

    +desktop
      grid-template-columns: 1fr 1fr 1fr

    list-style-type: none
    padding: 0

  &__exercise
    margin: 0

  &__exercise, &__exercise-button
    border-radius: $spacing * 0.5

  &__exercise-button
    display: flex
    padding: $spacing * 0.5
    align-items: center
    gap: $spacing * 0.5
    width: 100%
    justify-content: flex-start
    text-align: left

  &__exercise-title
    margin: 0
    font-weight: inherit

  &__description
    font-style: italic
    margin: 0
    flex: 1 1 0

    // If the description is only one line of text, center it with the other controls. Otherwise,
    // controls will align to the top of the description.
    align-self: center

    +less-than-tablet
      text-align: center

  &__controls
    margin: $spacing 0
    display: flex
    gap: $spacing * 0.5
    align-items: center
    justify-content: center

    +less-than-tablet
      flex-direction: column

  &__horizontal-rule
    margin: $spacing auto

    +desktop
      margin-top: $spacing
      margin-bottom: $spacing

  &__exercise-icon
    flex: 0 0 auto
