.chessboard-pieces

  &__label
    margin-bottom: $spacing * 0.25
    margin-left: $spacing * 0.5

  &__pieces
    display: grid
    grid-template-columns: repeat(6, 1fr)
    background-color: $color--tundora
    padding: $spacing * 0.25
    border-radius: $spacing * 0.75

  &__piece
    width: 100%
    height: auto
    padding: $spacing * 0.125
    border-radius: $spacing * 0.5
    cursor: grab

    &[draggable="false"]
      opacity: 0.4

    &[draggable="true"]:hover
      background-color: transparentize($color--black, 0.85)
