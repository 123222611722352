.icon-modal
  padding: 0 $spacing
  text-align: center

  &__icon
    display: block
    width: $spacing * 3
    height: $spacing * 3
    margin: $spacing auto

  &__text
    margin: $spacing 0

  &__actions
    display: flex
    justify-content: center
    column-gap: $spacing
    row-gap: $spacing * 0.5
    margin-bottom: $spacing 0 $spacing * 1.5
    align-items: center
    margin: $spacing 0

    & > *
      flex: 1 1 0

    &--stacked
      flex-direction: column
      align-items: stretch
      width: fit-content
      margin-left: auto
      margin-right: auto
