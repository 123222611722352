.lesson-content
  +page-padding

  display: flex
  flex-direction: column
  width: 100%
  height: 100%

  grid-template-rows: auto 1fr auto

  gap: $spacing * 0.5

  +desktop
    gap: $spacing

  &__preview
    flex: 1 1 0
