.legal-page
  display: block
  max-width: $tablet-width
  margin: 0 auto
  padding: 0 $spacing * 0.5
  overflow: auto

  h1
    font-size: 1.5rem

  h2
    font-size: 1.25rem

  h3, h4
    font-size: 1.1rem

  h1, h2, h3, h4, p
    margin: $spacing 0
