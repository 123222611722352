@use "sass:map"

.chessboard

  &__arrow
    position: absolute
    top: 0
    bottom: 0
    left: 0
    right: 0
    width: 100%
    height: 100%

  .marker
    opacity: 0.7

  // NOTE: Using camel casing in these class names goes against this project's conventions. However,
  // CMChessboard requires me to pass classes, and I'd rather create them programmatically.
  @each $marker, $color in $marker-colors
    .chessboard__marker--#{ $marker }
      fill: $color

  .chessboard__marker--lastMoveFrom, .chessboard__marker--lastMoveTo, .chessboard__marker--selected, .chessboard__marker--drag
    opacity: 0.4

  .chessboard__marker--mistake
    opacity: 1

    &.chessboard__marker--light
      fill: saturate(lighten(map.get($state-colors, "mistake"), 7%), 1%)

  .chessboard__marker--user
    opacity: 0.8

  .chessboard__marker--check
    opacity: 0.15

  // These classes are part of cm-chessboard. I don't have direct control over the class names.
  .coordinate

    &.white
      fill: $color--true-v

    &.black
      fill: $color--concrete
