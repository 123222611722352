@use "sass:math"

.primary-navigation

  // HACK: These are special local media queries for displaying the mobile navigation. They're
  // slightly larger than the original tablet size to accommodate additional menu items. It's not
  // worth standardizing these queries since they're only used here.
  $primary-navigation-breakpoint: 720px

  @mixin desktop-primary-navigation
    @media only screen and (min-width: $primary-navigation-breakpoint)
      @content

  @mixin mobile-primary-navigation
    @media only screen and (max-width: #{ $primary-navigation-breakpoint - 1px })
      @content

  $stagger-time: 50ms
  $number-of-items: 4

  +desktop-primary-navigation
    background-color: $color--black
    padding: $spacing * 0.5

  &
    font-size: 0.8rem

  // On tablet and above, display the navigation items inline
  &, &__items
    display: flex

    +mobile-primary-navigation
      flex-direction: column

      // Ensure the items display over everything else.
      z-index: 2000

    +desktop-primary-navigation
      gap: $spacing
      align-items: center

  +desktop
    padding-left: $spacing
    padding-right: $spacing

  &__items

    +mobile-primary-navigation
      position: absolute
      top: $navigation-height
      left: 0
      height: calc(100% - #{ $navigation-height })

      gap: $spacing
      justify-content: center

      transition: background-color $transition-time--medium ease-in-out
      background-color: transparentize($color--mine-shaft--dark, 0.025)

      &:not(.primary-navigation__items--open)
        background-color: transparent
        pointer-events: none

  &__title
    font-size: inherit
    margin: 0 auto 0 0

    // Position the title relatively so it covers up the animating items
    position: relative
    z-index: 100

    .primary-navigation__icon
      height: 1.25rem

    +mobile-primary-navigation
      background-color: $color--mine-shaft--darkest

  &__link
    color: inherit
    display: flex
    align-items: center

    &:not(:hover)
      text-decoration: none

    +mobile-primary-navigation
      height: $navigation-height
      padding: 0 $spacing * 0.5
      justify-content: center
      transition: all $transition-time--small ease-in-out
      width: 100vw

      @for $index from 1 through 8
        &:nth-last-of-type(#{ $index })
          transition-delay: $index * $stagger-time

      .primary-navigation__items:not(.primary-navigation__items--open) &
        transform: translate3d(0, -$spacing * 2, 0)
        opacity: 0

        // Delay the closing transition until all of the staggering has completed
        transition-delay: $number-of-items * $stagger-time

        // Reverse the transitions when the open class is not provided
        @for $index from 1 through 8
          &:nth-of-type(#{ $index })
            transition-delay: $index * $stagger-time

  &__icon
    margin-right: $spacing * 0.25
    height: 1rem

    &--popular
      transform: translateY(-0.1rem)

  &__open-button
    position: absolute
    top: 0
    right: 0

    +desktop-primary-navigation
      display: none

  &__beta
    color: $color--true-v
    margin-left: 0.25em
