.spoiler
  position: relative

  &::before
    content: ""
    position: absolute
    top: 0
    right: 0
    bottom: 0
    left: 0

    transition: background-color $transition-time--xsmall linear
    border-radius: $spacing * 0.125

  &--enabled
    cursor: pointer

  &--enabled::before
    background-color: transparentize($color--concrete, 0.85)

  &--hidden
    user-select: none

    &::before

      background-color: $color--concrete
