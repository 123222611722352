.chessboard-coordinates
  display: grid
  grid-template-columns: 1fr repeat(8, 2fr) 1fr
  grid-template-rows: 1fr repeat(8, 2fr) 1fr

  width: 100%
  height: 100%

  $ranks: (1 2 3 4 5 6 7 8)
  $files: (a b c d e f g h)

  &__content
    grid-column: 2 / span 8
    grid-row: 2 / span 8
    position: relative

  &__coordinate
    display: flex
    align-items: center
    justify-content: center

    max-width: 100%
    max-height: 100%
    margin: auto

    pointer-events: none

    &--left
      grid-column: 1

    &--right
      grid-column: 10

    &--top
      grid-row: 1

    &--bottom
      grid-row: 10

    @for $index from 1 through 8
      .chessboard-coordinates[data-orientation="white"] &[data-rank="#{ nth($ranks, $index) }"]
        grid-row: 8 - $index + 2

      .chessboard-coordinates[data-orientation="black"] &[data-rank="#{ nth($ranks, $index) }"]
        grid-row: $index + 1

      .chessboard-coordinates[data-orientation="white"] &[data-file="#{ nth($files, $index) }"]
        grid-column: $index + 1

      .chessboard-coordinates[data-orientation="black"] &[data-file="#{ nth($files, $index) }"]
        grid-column: 8 - $index + 2

  &__text
    fill: $color--boulder

    font-size: 50%
    font-weight: 600

    text-anchor: middle
    dominant-baseline: central
