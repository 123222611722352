.welcome-page
  +page-padding($vertical: false)
  max-width: $desktop-width
  margin: 0 auto

  &__section
    margin: $spacing * 2 0

    +desktop
      margin: $spacing * 3 0

  &__header
    display: flex
    gap: $spacing * 0.5
    align-items: center
    border-bottom: 1px solid $color--tundora
    padding-bottom: $spacing * 0.5

  &__lessons
    list-style: none
    padding: 0
    display: grid
    margin: $spacing * 0.5 #{ -$spacing * 0.5 }

    +tablet
      grid-template-columns: 1fr 1fr

    +desktop
      grid-template-columns: 1fr 1fr 1fr

  &__lesson-anchor
    display: grid
    column-gap: $spacing * 0.5
    grid-template-rows: auto auto
    grid-template-columns: auto 1fr

    color: inherit
    padding: $spacing * 0.5
    border-radius: $spacing * 0.5

    &:hover
      text-decoration: none
      background-color: $color--mine-shaft--dark

  &__lesson-icon
    height: $spacing * 1.5
    grid-row: 1 / span 2

    +less-than-tablet
      margin: auto 0

    +desktop
      height: $spacing * 2

  &__lesson-title, &__lesson-source-name
    margin: 0

  &__lesson-source-name
    font-style: italic
    color: $color--silver

  &__stats
    margin: $spacing 0

  &__empty
    font-style: italic
    margin: $spacing 0
    color: $color--silver

  &__empty-link
    color: inherit

    &:not(:hover)
      text-decoration: underline

  &__header-link
    color: inherit

  &__blurb
    margin: $spacing auto

    +desktop
      margin-top: $spacing
      margin-bottom: $spacing
