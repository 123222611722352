.lesson-controls

  // Since the rightmost button is solid, make it appear to have the same padding as the left.
  &.lesson-controls
    padding-right: $spacing * 0.5

    +desktop
      padding-right: $spacing

  &__practice
    margin-left: auto
