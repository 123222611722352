.edit-lesson-sidebar
  padding: 0 $spacing

  &__actions
    display: flex
    justify-content: center
    margin: $spacing auto

  &__delete
    padding-left: $spacing * 1.5
    padding-right: $spacing * 1.5

  &__row
    display: flex
    column-gap: $spacing
    margin: $spacing 0

    & > *
      flex: 1 1 0
      margin: 0

  &__publish
    justify-content: center
    margin: $spacing * 1.5 0

  &__publish-button
    display: flex
    margin: 0 auto
    min-width: 50%

    &--published
      background-color: $color--scorpion

      &:hover
        background-color: lighten($color--scorpion, 5%)

  &__publish-text
    font-style: italic
    font-size: 0.8rem
    text-align: center
    opacity: 0.5
    margin: $spacing * 0.25 0
