@use "sass:math"

.video-container
  $aspect-ratio: math.div(9, 16)

  padding-top: 100% * $aspect-ratio
  position: relative
  height: 0
  background-color: $color--mine-shaft--darkest

  // BUG FIX: For some reason, the youtube class name isn't applying to the container div. This is a
  // quick fix to add styles for that element.
  & > *
    position: absolute
    width: 100%
    height: 100%
    top: 0

  iframe
    width: 100%
    height: 100%
