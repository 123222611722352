.sidebar
  display: flex
  flex-direction: column

  background-color: $color--mine-shaft

  +desktop
    background-color: $color--mine-shaft--dark

  // This is an invisible button that sides over the top of the sidebar and allows it to be opened.
  &__open-button
    position: absolute
    top: 0
    right: left
    width: 100vw
    z-index: 100

  &__controls
    height: $navigation-height
    background-color: $color--mine-shaft--dark
    position: relative
    font-size: 0.9rem

    +desktop
      background-color: $color--mine-shaft--darkest

    &--hidden
      pointer-events: none
      opacity: 0

  &__content-container
    position: relative
    flex: 1 1 0
    overflow: hidden

  &__content
    position: absolute
    top: 0
    left: 0
    bottom: 0
    right: 0

    overflow-x: hidden
    overflow-y: auto

    transition: transform $transition-time--small ease-in-out

    &--left, &--right
      visibility: hidden
      animation: $transition-time--small hide

    &--left
      transform: translateX(-100%)

    &--right
      transform: translateX(100%)
      animation: $transition-time--small hide

  &__title
    margin: 0
    height: $navigation-height
    text-align: center
    line-height: $navigation-height
    background-color: $color--mine-shaft--darkest


// HACK: If the element's visibility is set to none, then it won't be tabbed to. This sets the
// visibility to none after a delay.
@keyframes hide
  0%
    visibility: visible
  99.9999%
    visibility: visible
  100%
    visibility: hidden
