.aspect-ratio-container
  display: flex
  align-items: center
  justify-content: center

  // HACK: This prevents issues where a larger chessboard won't allow the content to resize.
  overflow: hidden

  &__content
    position: relative

    & > *
      width: 100%
      height: 100%
