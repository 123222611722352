// NOTE: The vote buttons are very different than the other buttons in the application. I made the
// decision to *not* make them a standard treatment for now. If I end up using these styles
// elsewhere, they can be standardized and move to button.
.vote-button
  position: relative

  &:disabled
    opacity: 1

  // In order to override the button, the selector needs to be more specific, so it's duplicated
  // here.
  &.vote-button

    position: relative

    &--voted

      &::after
        content: ""
        display: block
        position: absolute
        left: $spacing * 0.5
        right: $spacing * 0.5
        bottom: 0.125 * $spacing
        height: 1.5px
        background-color: $color--silver

        +desktop
          left: $spacing * 0.675
          right: $spacing * 0.675
