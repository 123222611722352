.practice-score
  pointer-events: none

  +less-than-desktop
    display: grid
    align-items: center
    height: $navigation-height
    gap: $spacing
    padding: 0 $spacing * 0.5

    // This grid pattern allows for the stats to be centered in the container.
    grid-template-columns: $spacing * 1.25 1fr repeat(5, auto) 1fr $spacing * 1.25
    background-color: $color--mine-shaft--dark

  +less-than-tablet
    gap: $spacing * 0.25

  +desktop
    display: grid
    grid-template-columns: 1fr auto 1fr
    grid-template-rows: 1fr 1fr

  &__donut, &__stat--complete
    +desktop
      grid-column: 2
      grid-row: 1 / span 2
      width: $spacing * 6
      height: $spacing * 6

  &__stat--complete .practice-score__label
    text-align: center
    padding: 0 $spacing
    line-height: 1.5

  &__donut
    +less-than-desktop
      width: $spacing * 1.25
      height: $spacing * 1.25

  &__stat
    display: flex
    align-items: center
    justify-content: center
    flex-direction: column

    &:first-of-type
      +less-than-desktop
        grid-column: 3

    +less-than-desktop
      width: $spacing * 2

  &__value, &__label
    margin: 0

    +less-than-desktop
      line-height: 1.25

  &__value
    font-weight: bold

    +less-than-tablet
      font-size: 0.8rem

    +desktop
      font-size: 1.1rem

  &__label
    font-size: 0.75rem
    text-transform: uppercase

    +less-than-desktop
      font-size: 0.625rem

  &__abbreviation
    text-decoration: none
