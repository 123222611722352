.edit-exercise-page
  display: flex
  flex-direction: column
  height: 100%

  &__title
    text-align: center

  &__chessboard
    flex: 1 1 0
