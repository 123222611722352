@use "sass:math"

.button

  // Ideally, when a button's text is resized, it will automatically adjust to match its content
  // size. Since sizing is normally based on rem units, and the button's font size is 1rem, we can
  // use ems to all the buttons to be easily resized with a single value (font-size).
  $button-spacing: math.div($spacing, 1rem) * 1em

  display: inline-flex
  align-items: center
  justify-content: center

  font-size: inherit
  color: $color--concrete
  text-align: center

  background-color: transparentize($color--black, 0.75)
  border: none
  line-height: $button-spacing
  outline: none
  border-radius: $button-spacing * 10
  user-select: none

  transition: all $transition-time--xxsmall linear

  // BUG FIX: This fixes the strange button behavior in Safari.
  -webkit-appearance: none

  &:not(:disabled)
    cursor: pointer

  &:disabled
    opacity: 0.4

  &:hover
    background-color: transparentize($color--black, 0.5)
    text-decoration: none

  &__icon
    height: 1.25em

  // When the button has both text and an icon, make it slightly less wide.
  &--has-icon.button--has-text
    padding: $button-spacing * 0.25 $button-spacing * 0.75

  // When the button only has text, make it a little wider.
  &--has-text:not(.button--has-icon)
    padding: $button-spacing * 0.25 $button-spacing

  // When the button has an icon but not text, make it slightly larger.
  &--has-icon:not(.button--has-text)
    padding: $button-spacing * 0.375

  &--has-text &__icon
    height: 1em
    margin-right: $button-spacing * 0.375

  &--primary
    background-color: $color--true-v

    &:hover
      background-color: saturate(lighten($color--true-v, 5%), 10%)

  &--positive
    background-color: $color--aqua-forest

    &:hover
      background-color: saturate(lighten($color--aqua-forest, 5%), 10%)

  &--clear
    background-color: transparent

    &:hover
      background-color: transparentize($color--black, 0.7)

    &:disabled:hover
      background-color: transparent

  &--selected
    background-color: transparentize($color--black, 0.7)

  &--danger
    background-color: $color--fuzzy-wuzzy-brown

    &:hover
      background-color: lighten($color--fuzzy-wuzzy-brown, 3%)

    &:disabled, &:disabled:hover
      background-color: transparentize($color--black, 0.5)

  &--highlight
    background-color: $color--concrete
    color: $color--mine-shaft--darkest

    &:hover
      background-color: $color--white
