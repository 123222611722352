.authentication-page

  &__content
    padding: 0 $spacing

  &__actions
    display: flex
    justify-content: center
    margin: $spacing * 1.5 0 $spacing

  &__message
    font-style: italic
    text-align: center

  &__error
    color: $color--fuzzy-wuzzy-brown
    font-weight: inherit
    font-style: italic
    text-align: center

  &__error-link.authentication-page__error-link
    color: inherit
    text-decoration: underline
