.preview-link
  display: flex
  flex-direction: column
  gap: $spacing * 0.5
  min-width: $spacing * 12

  background-color: $color--mine-shaft--dark
  padding: $spacing * 0.75
  margin: $spacing auto
  border-radius: $spacing * 0.5
  text-align: center

  color: inherit

  &__icon
    display: block
    margin: 0 auto

  &:hover
    background-color: $color--mine-shaft--darkest
    text-decoration: none

  &__title, &__source-name, &__hostname
    margin: 0

  &__title
    font-weight: bold

  &__hostname
    font-style: italic

  &__icon
    height: $spacing * 2.5
