.root-page
  @mixin shadow
    $shadow-color: transparentize($color--mine-shaft--darkest, 0.5)
    box-shadow: 0 $spacing ($spacing * 2) $shadow-color

  @mixin angled-top($top-color, $bottom-color)
    $height: 4vw

    position: relative

    &::before, &::after
      content: ""
      display: block
      position: absolute
      top: -$height * 0.5
      left: 0
      right: 0
      height: $height

    &::before
      background-color: $top-color
      clip-path: polygon(0% 0%, 100% 0%, 0% 100%)

    &::after
      background-color: $bottom-color
      clip-path: polygon(0% 100%, 100% 0%, 100% 100%)

  margin: 0 auto

  // Make the text on the landing page just a little bigger than normal
  +tablet
    font-size: 1.05rem

  +desktop
    font-size: 1.1rem

  &__header, &__feature, &__call-to-action

    // This padding simulates a max width while allowing the background to stretch across the
    // container without an extra container element.
    padding: $spacing * 2 $spacing * 0.5

    +desktop
      padding: $spacing * 4 calc(50% - #{ $desktop-width * 0.5 } + #{ $spacing * 2 })

  &__video
    +shadow
    margin-bottom: $spacing * 2

  &__large-header, &__small-header, &__subhead
    margin: $spacing 0
    line-height: 1.2em

  &__large-header, &__subhead
    text-align: center

  &__large-header
    font-size: 2.5em
    font-weight: 900

  &__small-header
    // font-size: 1.5em
    font-size: 1.25em
    font-weight: 700

  &__subhead
    font-style: italic
    font-size: 1.1em

  &__subhead, &__paragraph
    color: $color--silver
    line-height: 1.5em
    max-width: 60ch
    margin-left: auto
    margin-right: auto

  &__feature
    display: grid
    gap: $spacing
    align-items: center

    +tablet
      grid-template-columns: repeat(2, 1fr)

    +desktop
      grid-template-columns: repeat(5, 1fr)


    &:nth-of-type(odd)
      +angled-top($color--mine-shaft, $color--mine-shaft--dark)
      background-color: $color--mine-shaft--dark

      .root-page__feature-content
        +tablet
          order: -1

    &:nth-of-type(even)
      +angled-top($color--mine-shaft--dark, $color--mine-shaft)

  &__feature-content

    & > :first-child
      margin-top: 0

    & > :last-child
      margin-bottom: 0

    +desktop
      grid-column-end: span 2

  &__feature-screenshot
    +shadow
    border: $spacing * 0.125 solid $color--mine-shaft--darkest
    max-width: 100%

    // This is used to ensure the feature box shadows are always displayed above the section
    // transitions.
    position: relative
    z-index: 100

    +desktop
      grid-column-end: span 3

  &__actions
    display: flex
    justify-content: center

  &__feature-header
    width: fit-content

    +less-than-tablet
      margin-left: auto
      margin-right: auto

  &__call-to-action
    +angled-top($color--mine-shaft, $color--mine-shaft--dark)

    background-color: $color--mine-shaft--dark

  &__hr
    margin-top: $spacing

  &__strong
    color: $color--concrete
    font-style: italic
