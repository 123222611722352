// The icons have a default size. However, this size is easy to override for more specific
// circumstances. *Icons should be sized using their height, not their width.*
.icon
  height: $icon-size

  // Allow the fill color to be easily overridden on the parent element.
  &--filled
    fill: currentColor

    path
      fill: inherit
