.input-with-label
  margin: $spacing 0

  &__labels
    display: flex
    column-gap: $spacing
    margin: 0 $spacing * 0.5 $spacing * 0.25

  &__label, &__error
    margin: 0

  &__label
    flex: 0 0 auto

  &__error
    flex: 1 1 0

    text-align: right
    font-weight: 600
    font-style: italic

  &__required
    text-decoration: none
    color: $color--true-v

  &--showing-error
    .input-with-label__labels, .input-with-label__required
      color: lighten($color--fuzzy-wuzzy-brown, 2%)
