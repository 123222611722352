input, .input, textarea
  display: block
  width: 100%

  padding: $spacing * 0.25 $spacing * 0.5

  background-color: $color--tundora
  border: none
  outline: none
  border-radius: $spacing * 0.75

  color: $color--concrete
  font-family: $font-family--sans-serif
  font-size: inherit

  line-height: $spacing

  // BUG FIX: Sometimes when auto-sized textareas are displayed in zoomed windows, they can have
  // sub-pixel rounding errors, which cause scroll bars to be displayed. This prevents those
  // scrollbars.
  overflow: hidden

  // The variates are styled with box shadows so the borders don't interfere with the layout
  &--selected, &--selected:focus
    box-shadow: 0 0 0 $spacing * 0.125 $color--boulder

  &--error, &--error:focus
    box-shadow: 0 0 0 $spacing * 0.125 $color--fuzzy-wuzzy-brown

  &:focus
    box-shadow: 0 0 0 $spacing * 0.125 $color--silver

  &:disabled
    opacity: 0.3

textarea
  resize: none

.input--code
  font-family: $font-family--monospace
  font-size: $spacing
