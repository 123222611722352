html, body, #application
  height: 100%
  margin: 0

  // prevent the text from being resized while the phone is rotated in iOS
  -webkit-text-size-adjust: none

  font-family: $font-family--sans-serif
  font-size: $base-font-size
  font-weight: 400
  line-height: $base-line-height
  color: $color--concrete

  background-color: $color--mine-shaft

  // Use dark scrollbars
  color-scheme: dark
