// NOTE: This component uses margins for spacing instead of gap because the exercise scroll bar
// should stretch to the top of its area.
.practice-lesson-sidebar
  display: flex
  flex-direction: column

  &__score
    flex: 0 0 auto
    margin-bottom: $spacing

    +desktop
      margin-top: $spacing

  &__hr, &__controls
    margin: 0 $spacing * 0.5

    +desktop
      margin: 0 $spacing

  &__exercises
    list-style-type: none
    flex: 1 1 0
    padding: 0
    overflow: auto
    position: relative
    padding: $spacing * 0.5 0
    margin: $spacing $spacing * 0.5 0
    border-top: 1px solid $color--tundora

    +desktop
      padding-left: $spacing * 0.5
      padding-right: $spacing * 0.5

  &__button
    display: flex
    align-items: center
    justify-content: left
    text-align: left
    width: 100%
    border-radius: $spacing * 0.5
    padding: $spacing * 0.5
    margin-top: $spacing * 0.25
    margin-bottom: $spacing * 0.25

  &__icon
    flex: 0 0 auto
    margin-right: $spacing * 0.5

  &__title
    flex: 1 1 0

  &__controls
    display: grid
    grid-template-columns: 1fr
    flex-direction: column
    gap: $spacing * 0.5

    // HACK: This should really be handled in a more global way. This specific width was the right
    // width, but I'd like to standardize this.
    @media only screen and (min-width: 360px)
      grid-template-columns: 1fr 1fr

    +desktop
      grid-template-columns: 1fr

    +large-desktop
      grid-template-columns: 1fr 1fr

  // HACK: This is necessary to fit the buttons in the sidebar space.
  &__control
    padding-left: 0
    padding-right: 0
