.donut
  width: 100%
  height: 100%

  &__circle
    fill: transparent

    @each $state, $color in $state-colors
      &[data-state="#{ $state }"]
        fill: $color

    // Override the background color
    &[data-state="unsolved"]
      fill: $color--tundora
