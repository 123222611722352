.practice-lesson-page

  display: flex
  flex-direction: row
  transition: $transition-time--medium ease transform
  align-items: stretch
  height: 100%

  // Translate the lesson based on the current practice index
  $page-offset: "(var(--current-practice-index) + 1) * -1"
  transform: translateX(calc(100vw * #{ $page-offset }))

  +desktop
    transform: translateX(calc((100vw - #{ $side-width }) * #{ $page-offset }))

  +large-desktop
    transform: translateX(calc((100vw - #{ $large-side-width }) * #{ $page-offset }))

  &__page
    flex: 0 0 auto

    // HACK: This is absolutely necessary to get the container to shrink the practice to fit. We can
    // only get away with this because we know the explicit width of the container.
    width: 100vw

    +desktop
      width: calc(100vw - #{ $side-width })

    +large-desktop
      width: calc(100vw - #{ $large-side-width })

  &__empty-chessboard
    width: 100%
    height: 100%
