.switch-with-label
  display: flex
  width: 100%
  gap: $spacing * 0.5
  align-items: center
  user-select: none
  cursor: pointer

  &__input
    display: none

  &__svg
    height: $spacing * 1.25

  &__svg-circle, &__svg-rect
    transition: all $transition-time--xsmall ease-in-out

  &[data-value="true"]
    .switch-with-label__svg-circle
      fill: $color--concrete

    .switch-with-label__svg-rect
      fill: $color--true-v

  &[data-value="false"]
    .switch-with-label__svg-circle
      fill: $color--boulder

    .switch-with-label__svg-rect
      fill: $color--tundora
