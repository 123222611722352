// This app contains five areas to be displayed. The Main area is always displayed, but the other
// areas can be selectively displayed or hidden depending on the page. This leaves 16 possible
// combinations, both for desktop and mobile layouts. In order to accommodate this, we need to
// correctly set the horizontal and vertical positioning attributes of each are depending on which
// areas are displayed.
//
// Primary Navigation   Secondary Navigation   Sidebar   Controls
// ==================   ====================   =======   ========
// ✔️                    ✔️                      ✔️         ✔️
// ✔️                    ✔️                      ✔️         ✘
// ✔️                    ✔️                      ✘         ✔️
// ✔️                    ✔️                      ✘         ✘
// ✔️                    ✘                      ✔️         ✔️
// ✔️                    ✘                      ✘         ✘
// ✔️                    ✘                      ✔️         ✔️
// ✔️                    ✘                      ✘         ✘
// ✘                    ✔️                      ✔️         ✔️
// ✘                    ✔️                      ✔️         ✘
// ✘                    ✔️                      ✘         ✔️
// ✘                    ✔️                      ✘         ✘
// ✘                    ✘                      ✔️         ✔️
// ✘                    ✘                      ✘         ✘
// ✘                    ✘                      ✔️         ✔️
// ✘                    ✘                      ✘         ✘
//
// These combinations also need to be considered for mobile and desktop layouts.

.application

  // Make the application take up the entire screen and hide anything that display outside of its
  // area.
  width: 100vw
  overflow: hidden

  // FIX: Unfortunately, 100vh doesn't work in Safari on mobile. You *have* to use 100% instead, or
  // the container height won't display properly.
  height: 100%

  // It would be great to be able to use CSS grid for this. Unfortunately, Chrome doesn't yet
  // implement CSS grid animations. As a workaround, this uses a positional layout to display all of
  // the components.
  position: relative

  // DEFAULT STYLES

  &__primary-navigation, &__secondary-navigation, &__sidebar, &__controls, &__main
    position: absolute
    width: 100vw

  // The primary navigation is always in the same location with the same size.
  &__primary-navigation
    top: 0
    left: 0
    height: $navigation-height
    z-index: 500

  &__secondary-navigation
    z-index: 100

  &__controls
    z-index: 400
    height: $navigation-height

  &__sidebar
    z-index: 200

    +less-than-desktop
      transition: transform $transition-time--small ease-in-out

@each $primary-navigation in (true false)
  @each $secondary-navigation in (true false)
    @each $sidebar in (true false)
      @each $controls in (true false)

        +application-class($primary-navigation, $secondary-navigation, $sidebar, $controls)

          .application__primary-navigation
            @if not $primary-navigation
              display: none

          .application__secondary-navigation
            @if not $secondary-navigation
              display: none

            top: bar-offset($primary-navigation)

          .application__sidebar
            @if not $sidebar
              display: none

            +less-than-desktop
              $other-bars-offset: bar-offset($primary-navigation, $controls)

              top: bar-offset($primary-navigation)
              height: calc(100% - #{ $other-bars-offset })

              // HACK: Ideally, this state would be styled in the Sidebar component. However, doing
              // so would require a lot of code duplication. This is an easier approach.
              &:not(.application__sidebar--open)
                transform: translate3d(0, calc(100% - #{ $navigation-height }), 0)

            +desktop
              width: $side-width
              right: 0
              top: bar-offset($primary-navigation)
              bottom: 0
              height: auto

            +large-desktop
              width: $large-side-width

          .application__controls
            @if not $controls
              display: none

            bottom: 0

          .application__main
            top: bar-offset($primary-navigation, $secondary-navigation)

            +less-than-desktop
              height: calc(100% - #{ bar-offset($primary-navigation, $secondary-navigation, $sidebar, $controls) })

            +desktop
              height: calc(100% - #{ bar-offset($primary-navigation, $secondary-navigation, $controls) })

          .application__secondary-navigation, .application__controls, .application__main
            @if $sidebar
              +desktop
                width: calc(100vw - #{ $side-width })

              +large-desktop
                width: calc(100vw - #{ $large-side-width })
