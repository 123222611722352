.exercise-move-row
  display: grid
  grid-template-columns: $spacing 1fr 1fr $spacing
  padding: $spacing * 0.5 0

  &::before
    counter-increment: exercise-moves
    content: counter(exercise-moves) "."

    display: flex
    align-items: center
    flex: 0 0 $spacing

    font-size: 0.9rem
    color: $color--silver

  &__input
    width: calc(100% - #{ $spacing * 0.5 })

    &--white
      grid-column-start: 2

    &--black
      margin-left: $spacing * 0.5
      grid-column-start: 3

    &:first-of-type
      margin-right: $spacing
