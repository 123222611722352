.edit-lesson-page
  display: flex
  flex-direction: column
  min-height: 100%

  padding: 0 $spacing
  max-width: $desktop-width
  margin: 0 auto

  &__exercise
    display: flex
    align-items: center
    column-gap: $spacing * 0.25

    background-color: $color--mine-shaft--dark
    margin-bottom: $spacing * 0.5
    border-radius: $spacing * 0.25
    padding: $spacing * 0.25 $spacing * 0.5

  &__exercise-drag-icon
    color: $color--boulder
    margin-right: $spacing * 0.5

  &__exercise-title
    margin: 0 auto 0 0

  &__message
    text-align: center
    max-width: ($mobile-width + $tablet-width) * 0.5
    margin: 0 auto
    font-style: italic

  &__actions
    display: flex
    justify-content: center
    margin: $spacing auto
