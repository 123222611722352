// These functions and mixing are used for manipulating the application layout. While they only
// apply to the application component, they're complex enough that they warrant their own file.

// This function returns a string representing whether the given class is present or not using the
// given flag.
@function flag-class($class, $flag) {
  @if $flag {
    @return $class;
  }
  @else {
    @return ":not(#{ $class })";
  }
}

// This function returns a string for the application class name using the given flags.
@function application-class($primary-navigation, $secondary-navigation, $sidebar, $controls) {
  @return ".application"
    + flag-class(".application--has-primary-navigation", $primary-navigation)
    + flag-class(".application--has-secondary-navigation", $secondary-navigation)
    + flag-class(".application--has-sidebar", $sidebar)
    + flag-class(".application--has-controls", $controls)
}

// This function is useful for determining how much a layout item should be offset based on the
// provided flag. For each flag that's true, this function increases the offset by the bar height.
@function bar-offset($flags...) {
  $count: 0;

  @each $flag in $flags {
    @if $flag {
      $count: $count + 1;
    }
  }

  @return $count * $navigation-height;
}

// Applies the correct application class to the provided content.
@mixin application-class($primary-navigation, $secondary-navigation, $sidebar, $controls) {
  #{ application-class($primary-navigation, $secondary-navigation, $sidebar, $controls) } {
    @content;
  }
}

// This applies responsive padding to a page in the application. This is done as a mixin instead of
// globally because padding doesn't always need to be applied to pages. Generally, I *hate* these
// types of mixins. However, in this case, it's useful because it keeps the padding consistent
// across pages.
@mixin page-padding($horizontal: true, $vertical: true) {
  @if ($horizontal) {
    padding-left: $spacing * 0.5;
    padding-right: $spacing * 0.5;

    @include desktop {
      padding-left: $spacing;
      padding-right: $spacing;
    }
  }

  @if ($vertical) {
    padding-top: $spacing * 0.5;
    padding-bottom: $spacing * 0.5;

    @include desktop {
      padding-top: $spacing;
      padding-bottom: $spacing;
    }
  }
}
