@use "sass:map"
@use "sass:color"

.practice-text
  display: flex
  justify-content: center
  align-items: center

  width: fit-content
  padding: $spacing * 0.25 $spacing * 0.5
  gap: $spacing * 0.5
  margin: 0 auto

  background-color: var(--state-color)
  color: var(--text-color)
  text-shadow: 0.5px 0.5px var(--dark-state-color), 0.5px -0.5px var(--dark-state-color), -0.5px 0.5px var(--dark-state-color), -0.5px -0.5px var(--dark-state-color)

  border-radius: $spacing
  font-style: italic

  &[data-state="unsolved"]
    --state-color: #{ $color--mine-shaft--darkest }
    --dark-state-color: #{ $color--mine-shaft--darkest }
    --text-color: #{ $color--white }

  &[data-state="perfect"]
    $state-color: map.get($state-colors, "perfect")
    --state-color: #{ $state-color }
    --dark-state-color: #{ saturate(darken($state-color, 8%), 3%) }
    --text-color: #{ $color--white }

  &[data-state="hint"]
    $state-color: map.get($state-colors, "hint")
    --state-color: #{ $state-color }
    --dark-state-color: #{ color.scale($state-color, $lightness: -17%, $saturation: 15%) }
    --text-color: #{ $color--mine-shaft--darkest }

  &[data-state="assist"]
    $state-color: map.get($state-colors, "assist")
    --state-color: #{ $state-color }
    --dark-state-color: #{ color.scale($state-color, $lightness: -12%, $saturation: -5%) }
    --text-color: #{ $color--mine-shaft--darkest }

  &[data-state="mistake"]
    $state-color: map.get($state-colors, "mistake")
    --state-color: #{ $state-color }
    --dark-state-color: #{ saturate(darken($state-color, 7%), 1%) }
    --text-color: #{ $color--white }

  &__code
    margin: 0 $spacing * 0.125
    background-color: var(--dark-state-color)

  &__text
    margin: 0
    flex: 1 1 0

    // HACK: Limit the line height. The only way I've found to do this is to use the old flexbox
    // layout, which browsers will still render, but which is out of compliance with the current
    // spec.
    display: -webkit-box
    -webkit-line-clamp: 3
    overflow: hidden
    -webkit-box-orient: vertical

    +desktop
      -webkit-line-clamp: 2

  &__description
    font-style: italic

  &__icon
    flex: 0 0 auto
